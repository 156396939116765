import React, { useCallback } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { makeStyles } from '@material-ui/styles'
import { Link as MUILink, Theme } from '@material-ui/core'
import messages from '@utils/MessageSets/p&aDynamicMessages'
import { useUserSession } from '@utils/useUserSession'
import { staticContent } from '@src/routes'
import { Link } from '@src/components/Link'
import clsx from 'clsx'
import { TrackedAvailabilityMessage } from '../TrackedAvailabilityMessage/TrackedAvailabilityMessage'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1.5,
    '& > div:not(:last-child)': {
      marginBottom: theme.spacing(1),
    },
  },
  link: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.secondary.dark,
    cursor: 'pointer',
  },
}))

export interface Availability {
  key: Maybe<string>
  date?: number | null
  plantLoc?: string | null
  quantity?: number | null
}

interface Props {
  availabilities: Availability[]
  distrChainStatus?: string | null | undefined
  className?: string
  appendLabel?: React.ReactElement | null
}

enum AvailabilityMessageKey {
  estimated = 'ESTIMATED_TO_SHIP_ON_DYNAMIC',
  available = 'AVAILABLE_TO_SHIP_ON_DYNAMIC',
  estimatedWithoutPlantLoc = 'ESTIMATED_TO_SHIP_ON_DYNAMIC_WITHOUT_PLANT_LOC',
  availableWithoutPlantLoc = 'AVAILABLE_TO_SHIP_ON_DYNAMIC_WITHOUT_PLANT_LOC',
  estimatedDelivery = 'ESTIMATED_DELIVERY_ON_DYNAMIC',
  estimatedDeliveryOn = 'ESTIMATED_DELIVERY_ON',
  shippingDateNotAvailable = 'SHIPPING_DATE_NOT_AVAILABLE',
  contactLocalCustomer = 'CONTACT_LOCAL_CUSTOMER_SERVICE_FOR_DELIVERY_ESTIMATE',
  limitedAvailability = 'PRODUCT_AVAILABILITY_LIMITED_AVAILABILITY',
  availabilityUnknown = 'AVAILABILITY_UNKNOWN',
  contactForAvailability = 'CONTACT_FOR_AVAILABILITY',
  fulfilmentDeliveryDelayed = 'FULFILMENT_DELIVERY_DELAYED',
  checkCartForAvailability = 'CHECK_CART_FOR_AVAILABILITY',
}

const AvailabilityMessage: React.FC<Props> = ({
  availabilities,
  distrChainStatus,
  className,
  appendLabel,
}) => {
  const { userSession } = useUserSession()
  const classes = useStyles()
  const intl = useIntl()

  const showDistrChainStatus =
    distrChainStatus === 'YC' ||
    distrChainStatus === 'YF' ||
    distrChainStatus === 'YR' ||
    distrChainStatus === 'YR05' ||
    distrChainStatus === 'EXCEPTION5' ||
    distrChainStatus === 'YC05' ||
    distrChainStatus === 'YF05'

  const renderMessage = useCallback(
    (
      key: AvailabilityMessageKey,
      availability: Availability,
      index: number
    ) => {
      const date = availability.date
        ? intl.formatDate(new Date(availability.date), {
            year: 'numeric',
            month: 'long',
            day: '2-digit',
            timeZone: 'UTC',
          })
        : null
      const plantLoc =
        availability && availability.plantLoc ? availability.plantLoc : null
      const quantity =
        availability.quantity && availabilities.length
          ? `${availability.quantity} `
          : null

      return availabilities.length ? (
        <React.Fragment key={index}>
          <TrackedAvailabilityMessage
            availability={availability}
            {...messages[key]}
            values={{ quantity, date, plantLoc }}
          >
            {(text) => (
              <div>
                {appendLabel && <>{appendLabel}: </>}
                {text}
              </div>
            )}
          </TrackedAvailabilityMessage>
        </React.Fragment>
      ) : null
    },
    // TODO: need a test before we refactor these dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const renderCustomerServiceMessage = useCallback(
    (
      key: AvailabilityMessageKey,
      availability: Availability,
      index: number
    ) => {
      return availabilities.length ? (
        <TrackedAvailabilityMessage
          key={index}
          availability={availability}
          {...messages[key]}
          values={{
            link: (
              <Link
                {...staticContent.index({
                  path: '/support/faq',
                  language: userSession.language,
                  country: userSession.country,
                })}
                passHref
              >
                <MUILink target="_blank" className={classes.link}>
                  <FormattedMessage
                    id="LOCAL_CUSTOMER_SERVICE"
                    defaultMessage="local customer service"
                  />
                </MUILink>
              </Link>
            ),
          }}
        >
          {(text) => <div>{text}</div>}
        </TrackedAvailabilityMessage>
      ) : null
    },
    []
  )

  const renderDistrChainStatus = (distrChainStatus) => {
    switch (distrChainStatus) {
      case 'YC':
        return intl.formatMessage({
          id: 'DELIVERY_DELAY_RESTRICTIONS',
          defaultMessage:
            'Delivery of this item may be delayed due to regulatory or compliance restrictions.',
        })
      case 'YF':
        return intl.formatMessage({
          id: 'DELIVERY_DELAY_TRANSPORTATION',
          defaultMessage:
            'Delivery of this item may be delayed due to its transportation regulations.',
        })
      case 'YR':
        return intl.formatMessage({
          id: 'DELIVERY_SAFETY_DOCUMENTATION',
          defaultMessage:
            'This item may have limited quality and safety documentation. Once ordered, it may not be canceled or returned.',
        })
      case 'EXCEPTION5':
        return intl.formatMessage({
          id: 'DELIVERY_DELAY_BY_TRUCK',
          defaultMessage: 'Delivered via Merck Truck; Delivery may be delayed.',
        })
      case 'YR05':
        return intl.formatMessage({
          id: 'DELIVERY_DELAY_LIMITED_QUANTITY',
          defaultMessage:
            'This item may have limited quality and safety documentation. Once ordered, it may not be cancelled or returned. Delivered via Merck Truck; Delivery may be delayed.',
        })
      case 'YC05':
        return intl.formatMessage({
          id: 'DELIVERY_DELAY_REGULATORY_OR_COMPLIANCE_RESTRICTIONS',
          defaultMessage:
            'Delivery of this item may be delayed due to regulatory or compliance restrictions. Delivered via Merck Truck; Delivery may be delayed.',
        })
      case 'YF05':
        return intl.formatMessage({
          id: 'DELIVERY_DELAY_TRANSPORTATION_REGULATIONS',
          defaultMessage:
            ' Delivery of this item may be delayed due to its transportation regulations. Delivered via Merck Truck; Delivery may be delayed.',
        })
      default:
        return null
    }
  }

  return (
    <div className={clsx(classes.root, className)}>
      {availabilities.map((availability, index) => {
        switch (availability.key) {
          case 'ESTIMATED_TO_SHIP_ON':
            return renderMessage(
              availability.plantLoc
                ? AvailabilityMessageKey.estimated
                : AvailabilityMessageKey.estimatedWithoutPlantLoc,
              availability,
              index
            )
          case 'AVAILABLE_TO_SHIP_ON':
          case 'SHIPS_ON_FROM':
            return renderMessage(
              availability.plantLoc
                ? AvailabilityMessageKey.available
                : AvailabilityMessageKey.availableWithoutPlantLoc,
              availability,
              index
            )
          case 'ESTIMATED_DELIVERY_ON':
            return renderMessage(
              availability.plantLoc
                ? AvailabilityMessageKey.estimatedDelivery
                : AvailabilityMessageKey.estimatedDeliveryOn,
              availability,
              index
            )
          case 'SHIPPING_DATE_NOT_AVAILABLE':
            return renderMessage(
              AvailabilityMessageKey.shippingDateNotAvailable,
              availability,
              index
            )
          case 'CONTACT_LOCAL_CUSTOMER_SERVICE_FOR_DELIVERY_ESTIMATE':
            return renderCustomerServiceMessage(
              AvailabilityMessageKey.contactLocalCustomer,
              availability,
              index
            )
          case 'EMPROVE_SUBSCRIBED_AVAILABILITY':
            return intl.formatMessage({
              id: 'EMPROVE_SUBSCRIBED_AVAILABILITY',
              defaultMessage:
                'Available for download once your purchase is completed',
            })
          case 'EMPROVE_NONSUBSCRIBED_AVAILABILITY':
            return intl.formatMessage({
              id: 'EMPROVE_NONSUBSCRIBED_AVAILABILITY',
              defaultMessage:
                'Available for download once your purchase is completed and approved',
            })
          case 'LIMITED_AVAILABILITY':
            return renderCustomerServiceMessage(
              AvailabilityMessageKey.limitedAvailability,
              availability,
              index
            )
          case 'CONTACT_FOR_AVAILABILITY':
            return intl.formatMessage({
              id: 'CONTACT_FOR_AVAILABILITY',
              defaultMessage:
                'Please contact Customer Service for Availability',
            })
          case 'CHECK_CART_FOR_AVAILABILITY':
            return intl.formatMessage({
              id: 'CHECK_CART_FOR_AVAILABILITY',
              defaultMessage: 'Check Cart for Availability',
            })
          case 'FULFILMENT_DELIVERY_DELAYED':
            return intl.formatMessage({
              id: 'FULFILMENT_DELIVERY_DELAYED',
              defaultMessage: 'Fulfilment and delivery delayed',
            })
          case 'OUT_OF_STOCK_KEY':
            return intl.formatMessage({
              id: 'OUT_OF_STOCK',
              defaultMessage:
                'We apologize but fulfillment and delivery of this product is delayed. We are working to minimize these delays as quickly as possible.',
            })
          default:
            return renderMessage(
              AvailabilityMessageKey.availabilityUnknown,
              availability,
              index
            )
        }
      })}
      {showDistrChainStatus && (
        <div>{renderDistrChainStatus(distrChainStatus)}</div>
      )}
    </div>
  )
}

export default AvailabilityMessage
